<template>
  <div class="Profil">
    <header class="jumbotron">
      <h3 v-if="currentUser.username" class="col-sm-8 mx-auto mt-3">Din profilside</h3><br>
    </header>
   <b-card no-body>
     <b-tabs content-class="mt-3" id="tabs">
       <b-tab title="Dine registrerte opplysninger" active>
         <div class="col-sm-8 mx-auto">
           <div class="card">
             <div class="card-body">
           <h5 v-if="currentUser.username" class="card-title">Du er registrert med e-post: {{currentUser.username}}</h5>
            <p class="card-text"> 
            Dine brukerroller:
            <ul>
              <li v-for="role in currentUser.roles" 
                  :key="role">{{role}}
              </li>
            </ul>
            </p>
              <!--<p class="card-text"> 
             Brukerkonto verifisert: 
             <ul>
               <li>
                   {{ currentUser.confirmed ? "Ja" : "Nei - se e-post, og verifiser. Hvis du ikke finner e-posten, sjekk søppelpost eller be om å få tilsendt verifiserings e-post på nytt her." }}
               </li>
             </ul>
             </p>-->
            <p class="card-text"> 
            Ditt brukenivå:
            <ul>
              <li v-if="currentUser.user_level == 0">
                  Lav - ved å utføre tutorial, øker ditt nivå og du kan dermed delta i meteorvurderinger!
              </li>
              <li v-if="currentUser.user_level == 1">
                  Middels, du har gjennomført tutorial og kan gi vurdering av meteorer. Dine vurderinger vil hjelpe oss å få bedre kvalitet på meteorobservasjonene!
              </li>
              <li v-if="currentUser.user_level == 2">
                  Høy - dine meteorvurderinger er vektet høyt!
              </li>
            </ul>
            </p>
             <p class="card-text"> 
            Tutorial utført:
            <ul>
              <li>                 
                  {{ currentUser.tutorial_completed ? "Ja" : "Nei" }}
              </li>
            </ul>
            <Tutorial></Tutorial>
            </p>

             <p> 
                       
             </p>
          </div>
        </div> 
      </div>                
  </b-tab>

      <b-tab v-if="Array.isArray(reviewsReportContent) && reviewsReportContent.length" title="Dine meteorvurderinger"> 
         <div class="col-sm-8 mx-auto">
         <div class="table-responsive">
          <table id="tableComponent" class="table table-bordered table-striped table-sm">
            <thead>
              <tr>
                <!-- loop through each value of the fields to get the table header -->
                <th v-for="field in reviewsReportContentFields" :key="field">
                  {{ field }}
                  <i class="bi bi-sort-alpha-down" aria-label="Sort Icon"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- Loop through the list get the each student data -->
              <tr v-for="item in reviewsReportContent" :key="item">
                <td v-for="field in reviewsReportContentFields" :key="field">                    
                    <router-link v-if="field == 'MeteorID'"  :to="{ name: 'Meteor', title: 'Meteordetaljer for meteor-ID'.concat( item[field]), params: { id: item[field] }}"> MeteorID {{ item[field] }}</router-link>
                    <span  v-if="field == 'Anbefaling' && item[field] == 1"  > Ja </span>  
                    <span  v-if="field == 'Anbefaling' && item[field] == 0"  > Nei </span>  
                    <span  v-if="field == 'Anbefaling' && item[field] == -1"  > Nullstilt/tilbaketrukket anbefaling</span>                   
                 
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          </div>
   </b-tab>
      <b-tab title="Informasjon" >
        <div class="col-sm-8 mx-auto">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title"> Informasjon:</h5>
                <p class="card-text">
                <ul>
                  <strong>Brukernivåer</strong>     
                  <li> Lavt nivå, du er registrert som bruker, men kan ikke klassifisere meteorer, før du har gjennomført tutorial. </li>
                  <li> Medium, du har gjennomført tutorial og får dele klassifiseringsforslag. Dine vurderinger vil bli gjennomgått av administrator </li>
                  <li> Høyt, du er nå godkjent til å klassifisere meteorer og din vurdering registreres direkte i systemet. </li>
                </ul>
             </p>
          </div>
        </div> 
      </div>
     </b-tab>
   </b-tabs>
</b-card>
</div>    
    

</template>

<script>
import UserService from "../../services/user.service";
import Tutorial from "../../components/UserTutorialModal.vue";

export default {
  name: "Profil",

  components: {
    Tutorial,
  },
  data() {
    return {
      title: "Profil",
      reviewsReportContent: [],
      reviewsReportContentFields: ["MeteorID", "Anbefaling"],
    };
  },

  methods: {
    getReviews() {
      const userid = this.currentUser.id;
      UserService.getMeteorReviews(userid).then(
        (response) => {
          const receivedData = response.data;
          if (Array.isArray(receivedData) && receivedData.length) {
            const mappedData = receivedData.map((a) => {
              return { MeteorID: a.meteor_id, Anbefaling: a.confirmed };
            });
            this.reviewsReportContent = mappedData;
          }
        },
        (error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
          );
        }
      );
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.getReviews();
  },
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 500px) {
  #open {
    left: 0px;
    right: 0px;
    z-index: 500;
    position: absolute;
    border: solid;
    background-color: #f0efed;
  }
}
</style>
