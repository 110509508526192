
  <template>
   <b-button @click="modalShow = !modalShow"
      >Gå til meteorvurderingstutorial</b-button
    >
  <div class="conditions">
   

    <b-modal v-model="modalShow" id="modal-tall" hide-footer title="Tutorial">
      <h6>Sjekkliste</h6>
      <img
        class="img-fluid"
        alt="ildkule"
        src="../assets/ildkule-20121112-mini.jpg"
      />
      <table>
        <tr>
          <td>
            <p align="justify">
              Følgende sjekkliste kan være nyttig for å vurdere om du har sett
              ei ildkule. Er ikke disse tingene oppfylt, har du antakelig sett
              noe annet (fyrverkeri, nødbluss, kinesisk lykt, satellitt, fly,
              ordinært stjerneskudd, osv).
            </p>

            <ul>
              <li>
                <i
                  >Beveger seg raskt over himmelen, langt raskere enn et fly.</i
                >
              </li>
              <li>
                <i>Beveger seg i rett linje, svinger ikke, snur ikke.</i>
              </li>
              <li>
                <i
                  >Lyser kraftigere enn noen stjerne eller planet på
                  himmelen.</i
                >
              </li>
              <li><i>Er ikke synlig foran terreng/trær/tett skydekke.</i></li>
              <li>
                <i
                  >Er synlig i mindre enn et halvminutt, oftest 1 - 10
                  sekund.</i
                >
              </li>
            </ul>

            <p>
              Andre observasjoner kan være av interesse, også om du ikke så noen
              ildkule direkte:
            </p>
            <ul>
              <li>
                <i
                  >Plutselig opplysning av terrenget (så skygger kommer til syne
                  om det er klarvær).</i
                >
              </li>
              <li>
                <i
                  >Lysende stripe eller flekk på himmelen som gradvis blir
                  svakere.</i
                >
              </li>
              <li>
                <i
                  >Drønn eller smell som ikke kan tilskrives torden eller
                  menneskelig aktivitet.</i
                >
              </li>
            </ul>

            <p>
              Merk: Mens torden følger få sekund etter et lyn, vil det gå minst
              et minutt før et drønn kan høres etter ei ildkule.
            </p>

            <p align="justify">
              Et eksempel på ei typisk ildkule kan ses i
              <a href="http://voksenlia.net/nytt/2012/ildkule-20120313.mp4"
                >denne videoen</a
              >
              fra Oslo. Den lyser i 5-6 sekund før den forsvinner bak ei sky.
              Lyset er langt kraftigere enn Venus, men ikke nok til å lyse opp
              landskapet. Et annet eksempel også fotografert fra Oslo er
              <a href="http://voksenlia.net/nytt/2012/ildkule-20120301.mp4"
                >denne videoen</a
              >, der vi først ser landskapet bli opplyst som om det var fullmåne
              (ildkula kalles da gjerne <i>bolide</i>), før kameraet fanger opp
              siste del av ildkulas ferd. Ved begge disse to hendelsene oppstod
              det et drønn, og det er gode muligheter for at det ligger
              meteoritter på bakken etter hendelsene. Vi kan regne med at det et
              eller annet sted i Norge hver måned inntreffer en eller flere
              slike hendelser, men de fleste av dem blir aldri observert.
            </p>
          </td>
        </tr>
      </table>

      <p align="justify">
        Det er svært vanlig å oppleve at ei ildkule faller ned i nærheten, rett
        bak en ås, like over et vann eller liknende. Som regel faller den mye
        lengre unna! Ildkuler slokner typisk 20-60 km over bakken, og for å se
        den tilsynelatende lyse helt ned mot bakken, må en da være langt unna,
        kanskje så langt unna som 200-300 km. Men en beskrivelse av hvor den
        <i>synes</i> å lande er likevel verdifulle opplysninger.
      </p>

      <hr />
      <h6>Litt om mer ildkuler</h6>

      <p align="justify"></p>
      <table width="40%" align="left">
        <tr>
          <td>
            <img
              alt="ildkule"
              src="../assets/iss.jpg"
              class="img-fluid"
            /><br /><small>Foto: Den internasjonale romstasjonen</small>
          </td>
        </tr>
      </table>
      <p align="justify">
        I solsystemet fins det uttallige små biter eller støvkorn av stein eller
        is som kalles meteoroider eller mikrometeoroider. Daglig kolliderer
        jorda med millioner av disse, og i tilstrekkelig mørke kan vi se dem som
        stjerneskudd. Disse er noen millimeter til noen få centimeter store, men
        fordi hastigheten er stor (flere titalls kilometer i sekundet), blir
        møtet med jordas øvre atmosfære så brutalt at de oppløses helt. De
        begynner å lyse ca. 100 km oppe, vi kaller dem da meteorer, og de
        oppløses typisk innen de kommer ned til 50 km. Større meteorer kan nå
        lengre ned og de lyser kraftigere. Noen ganger kan enkelte biter
        overleve helt ned til bakken, der de kalles meteoritter. Ildkuler er
        meteorer (stjerneskudd) som lyser kraftigere enn Venus på sitt klareste,
        og spesielt kraftige ildkuler kalles gjerne bolider. Disse er flere kilo
        store og oppover. Ofte brukes betegnelsen bolide om ildkuler som lyser
        kraftigere enn fullmånen, eller som lyser opp terrenget tilstrekkelig
        til at det dannes tydelige skygger. Om meteoren er på mange tonn, kan
        ildkula bli så kraftig at den lyser like sterkt som sola. Begrepet
        "bolide" har ingen streng definisjon og brukes litt ulikt. Andre
        egenskaper ved ei ildkule som gjør at enkelte vil kalle det en bolide,
        kan være at den eksploderer eller skaper et overlydssmell. Bolider er
        gode kandidater for meteorittnedfall.
      </p>

      <p align="justify">
        Kraftige ildkuler kan lyse helt ned til 20-30 km over bakken, der de
        også kan skape et overlydssmell som kan høres på bakken etter et eller
        flere minutt avhengig av avstand. Det er da også sannsynlig at rester
        kan ha falt helt ned til bakken, som regel bare små biter (men de kan
        være mange). Slike biter vil ikke lenger gløde og de vil falle omtrent i
        samme hastighet som om de var sluppet fra et fly. Som regel vil ikke en
        meteoritt være spesielt varm å ta på om den plukkes opp rett etter å ha
        truffet bakken. Spesielt kraftige bolider kan skape kraftige sjokkbølger
        og gjøre skader over store områder, som vi kjenner fra Tsjeljabinsk i
        2013 og Tunguska i 1908.
      </p>

      <p align="justify">
        Ildkuler lyser oftest i noen få sekund, men enkelte ganger om de treffer
        jordas atmosfære i en lav vinkel, kan de lyse i over 10 sekund. Det
        hender også at de kommer inn i en slik vinkel at de passerer gjennom den
        øvre atmosfæren og så forsvinner ut igjen.
      </p>

      <p align="justify">
        Meteorer faller både om natta og om dagen, men bare de eksepsjonelt
        kraftige vil være blikkfang om dagen. Dessuten må det også være klarvær
        for at de kan observeres. Så i Norge der vi har mer enn 50% skydekke i
        snitt over hele landet, enkelte steder vesentlig mer, kan vi i beste
        fall bare observere 25% visuelt. De aller fleste meteoritter faller
        altså ned uten at noen har kjennskap til det.
      </p>

      <p align="justify">
        Fordi vi kan observere så lite av det som faller ned og det meste av
        jorda er ubebodd, er det en viss usikkerhet rundt hvor vanlige de
        virkelig store bolidene er. Men vi kan omtrentlig regne med at jorda
        årlig treffes av meteorer på størrelse med en bil, altså flere tonn
        store. Oftest faller de ned over hav eller over øde områder, der få
        eller ingen vil se dem. Det kan gå 100 år mellom hver gang jorda treffes
        av meteorer på mange tusen tonn og med diameter på noen titalls meter,
        som er store nok til å gjøre skader over et større område. Meteorer noen
        titalls meter store kan oppdages og observerers før de treffer jorda,
        men foreløpig krever det en del gunstige betingelser og noe hell. De
        fleste av denne størrelsen vil vi ikke oppdage før de dukker opp i
        atmosfæren.
      </p>

      <hr />

      Publisert av
      <a href="mailto:steinar@norskmeteornettverk.no">Steinar Midtskogen</a>
      2013-03-01.
      <hr />
      <b-button
        class="mt-3"
        variant="success"
        block
        @click="confirmTutorialDone"
        >Bekreft gjennomgått tutorial</b-button
      >
      <b-button class="mt-3" variant="outline-danger" block @click="hideModal"
        >Lukk</b-button
      >
    </b-modal>
  </div>
</template>


<script>
import UserService from "../services/user.service";
export default {
  name: "meteortutorial",
  data() {
    return {
      modalShow: false,
    };
  },
  methods: {
    showModal() {
      this.modalShow = true;
    },
    confirmTutorialDone() {
      this.modalShow = false;
      const userid = this.currentUser.id;
      this.currentUser.tutorial_completed = true;
      UserService.setTutorialDone(userid).then(
        (response) => {
          console.log(response.data);
        },
        (error) => {
          this.reviewsReportContent =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },

    hideModal() {
      this.modalShow = false;
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
.conditions {
  color: white;
  padding-top: 1em;
  text-align: center;
}
</style>